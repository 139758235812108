<template>
  <div class="splash">
    <div class="callout">
      <div class="slide slide_1">
        <div v-for="(val, indx) in bubbles" :key="indx" v-bind:style="val" class="bubble"></div>
        <img
          src="@/assets/tuxsuite.svg"
          width="220"
          class="mb-12"

        />
        <p>Tuxsuite is a cloud-native highly concurrent Linux kernel development service.</p>
      </div>

    </div>
    <div class="access">
      <div class="early_access">
        <h2>Early Access</h2>
        <p>Tuxsuite is currently under active development, but we want to hear from you!
        If you are interested in joining the waiting list, or have questions,
        feedback, or feature requests, please email us at <a href="">tuxbuild@linaro.org.</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
    return {
      bubbles: [
        {left: "4%", top: "84vh"},
        {left: "60%", top: "20vh"},
        {left: "30%", top: "64vh", width: "100px", height: "100px", backgroundColor: "rgba(255,255,255, 0.3)"},
        {left: "10%", top: "30vh", width: "120px", height: "120px", backgroundColor: "rgba(255,255,255, 0.2)"},
        {left: "80%", top: "80vh", width: "80px", height: "80px", backgroundColor: "rgba(255,255,255, 0.4)"},
        {left: "50%", top: "90vh", width: "240px", height: "240px", backgroundColor: "rgba(255,255,255, 0.2)"},
      ]
    }
  },
  components: {},
  created() {
    if (this.$auth.isAuthenticated) {
      this.$router.push({path: "/home"})
    }
  }
}
</script>

<style lang="scss">
.splash {
  h2 {
    font-size: 32px;
    font-weight: 400;
  }
  p {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1px;
  }
}
.callout, .access {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bubble {
  width: 200px;
  height: 200px;
  // top: 44%;
  border-radius: 50%;
  // left: 10%;
  right: auto;
  background: hsla(0,0%,100%,.15);
  position: absolute;
}
.early_access {
  h2 {
    text-align: center;
    font-weight: 100;
    font-size: 48px;
  }
  p {
    text-align: center;
    font-size: 18px;
  }
  max-width: 500px;
}
.callout {
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  // background: linear-gradient(rgb(255,255,255) 5%, rgb(187, 222, 251) 94%);
  text-align: center;
  h2 {
    font-size: 32px;
    font-weight: 800;
  }
  p {
    max-width: 500px;

  }

}
</style>
