<template lang="html">

    <v-row class="token-row ml-3 mr-3 mb-6" justify="space-between" align="center">
      <v-col class="">{{userToken.FriendlyName}}</v-col>
      <v-col class="date">
        last used {{lastUsed}}
      </v-col>
      <!-- <v-col class="token-text">
        <h5>{{userToken.Token}}</h5>
      </v-col> -->
      <v-col align="right">
        <v-icon
          class="mr-4 ml-12"
          @click="showToken(userToken.Token)"
        >{{icons.eye}}</v-icon>
        <v-icon
          @click="deleteToken(userToken)"
          color="red">{{ icons.trash }}
        </v-icon>
      </v-col>
    </v-row>
</template>

<script>
import moment from 'moment';
import {
  mdiDelete,
  mdiEye
} from '@mdi/js'
export default {
  name: "TokenCard",
  props: ['userToken'],
  data() {
    return {
      defaults: "perm mr-1",
      icons: {
        trash: mdiDelete,
        eye: mdiEye
      }
    }
  },
  methods: {
    getTuxColor(name) {
      switch(name) {
        case "tuxbuild":
          return "blue"
        case "tuxtest":
          return "red"
        default:
          return "gray"
      }
    },
    async deleteToken(token) {
      await this.$store.dispatch('tokens/deleteToken', {ID: token.SK.split('#')[1]})
    }
  },
  computed: {
    lastUsed() {
      return moment.utc(this.userToken.LastUsed).fromNow()
    }
  }
}
</script>

<style lang="scss">
.token-row {
  border-left: 3px solid rgba(233,12,12,.8);
  transition: all .1s ease-in-out;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px;
  &:hover {
    background-color: rgba(255,255,255,.15);
    box-shadow: inset -2px 2px 2px rgba(255,255,255,.25), 1px 1px 2px rgba(0,0,0,.03);
  }

  .date {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(12,12,12,.6);
    font-style: italic;
    // text-shadow: 1px 1px 5px rgba(123,123,123,.3);
  }
}
.token-text {
  // font-family: "courier new";
  // font-size: 14px;
  // font-weight: 100;
  // letter-spacing: 1px;
  // background-color: rgba(123,123,123,.2);
  // line-height: 14px;
}
.perm {
  &.tuxtest {
    // background: green !important;
  }
}
</style>
