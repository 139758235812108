import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users.js'
import tokens from './modules/tokens.js'
import $axios from '@/plugins/axios.js'

import { getInstance } from "@/auth";

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'pr'

export default new Vuex.Store({
  state: {
    status: '',
    token: '',
    user : {}
  },
  mutations: {
    setToken(state, token) {
      $axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      state.token = token;
    }
  },
  getters : {
    token(state) {
      return state.token;
    }
  },
  actions: {
    retrieveTokenFromAuthz(context) {
      return new Promise((resolve, reject) => {
        const instance = getInstance();
        instance.$watch("loading", loading => {
          if (loading === false && instance.isAuthenticated) {
            instance
              .getTokenSilently()
              .then(authToken => {
                context.commit("setToken", authToken);
                resolve(authToken);
              })
              .catch(error => {
                reject(error);
              });
          }
        });
      });
    }
  },
  modules: {users,tokens},
  strict: debug
})
