<template lang="html">
  <div>
    create tokens
    <v-btn @click="createToken">Boston</v-btn>
  </div>
</template>

<script>
export default {
  name: "TokenCreate",
  data() {
    return {
      payload: {
        Policy: {
            tuxtest: {
              permissions: ["*"]
            }
        },
        PolicyVersion: "11-04-2020",
        FriendlyName: "my-token"
      }
    }
  },
  methods: {
    async createToken() {
      console.log(this.payload)
      let token = await this.$store.dispatch('tokens/createToken', this.payload)
      console.log(token)
    }
  },
  created() {
    console.log('token create')
  }
}
</script>

<style lang="scss">
</style>
