import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'


Vue.use(Vuex);
Vue.config.productionTip = false;


// Import the Auth0 configuration
import { Auth0Plugin } from "./auth";

const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const domain = "tuxsuite.us.auth0.com";
const audience = process.env.VUE_APP_API_AUDIENCE;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience: audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})

// instantiate the vue instance
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
