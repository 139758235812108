
const state = () => ({
  user: {},
  signinStatus: null
})

const getters = {

}

const actions = {

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
