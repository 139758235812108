import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

// views
import Splash from '../views/Splash.vue'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import TokenRouter from '../views/tokens/Router.vue'
import TokenMain from '../views/tokens/Main.vue'
import TokenCreate from '../views/tokens/Create.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/tokens',
    component: TokenRouter,
    beforeEnter: authGuard,
    children: [{
      name: 'TokenMain',
      path: '',
      component: TokenMain
    },{
      name: 'TokenCreate',
      path: 'create',
      component: TokenCreate
    }]
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
