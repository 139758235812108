<template>
  <v-app v-if="!$auth.loading">
    <v-app-bar
      app
      color="white"
      :height="$auth.isAuthenticated ? '80' : '90'"
    >
      <v-row>
        <v-col align-self="center">
          <img
            src="@/assets/tuxsuite.svg"
            class="logo"
          />

        </v-col>
        <v-spacer></v-spacer>
        <v-col align-self="center" class="nav_buttons mr-4">
          <!-- <v-icon class="mr-4" color="primary">{{twitterIcon}}</v-icon>
          <v-icon class="mr-5" color="primary">{{emailIcon}}</v-icon> -->
          <v-btn elevation="0" v-if="!$auth.isAuthenticated" class="mr-4" @click="login">Log in</v-btn>
          <!-- <v-btn v-if="!$auth.isAuthenticated" @click="signUp">sign up</v-btn> -->
          <v-btn elevation="0" v-if="$auth.isAuthenticated" @click="logout">Log out</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <Splash v-if="!$auth.isAuthenticated" />
    <v-main v-if="$auth.isAuthenticated && dataLoaded" >
      <NavLeft />
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>

</template>

<script>
import { mdiTwitter, mdiEmail } from '@mdi/js'
// import $axios from '@/plugins/axios.js'
import Splash from './views/Splash.vue'
import NavLeft from './views/NavLeft.vue'
export default {
  name: 'app',
  data() {
    return {
      twitterIcon: mdiTwitter,
      emailIcon: mdiEmail,
      dataLoaded: false
    }
  },
  computed: {
    elevation: () => {
      // return this.
      // return this.$router
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    signUp() {
      this.$auth.loginWithRedirect({ screen_hint: 'signup' });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  async created() {
    await this.$store.dispatch('retrieveTokenFromAuthz')
    this.dataLoaded = true;
  },
  components: {Splash,NavLeft}
}
</script>


<style lang="scss">
body, html {
  padding:0; margin:0;

}

.logo {
  height: 45px;
  margin-left: 20px;
}
.nav_buttons {
  display: flex;
  justify-content: flex-end;
}
.v-main {
  // background: #A1FFCE;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #FAFFD1, #A1FFCE);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #FAFFD1, #A1FFCE); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
#app {
  font-family: "Helvetica Neue", Open Sans, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(60,50,90);
}
body div.v-application .v-card {
  // box-shadow: 0 3px 11px 0 #e8eafc,0 3px 3px -2px hsla(0,0%,69.8%,0.1),0 1px 8px 0 hsla(0,0%,60.4%,0.1)!important;
  background: inherit;
  backdrop-filter: blur(10px);
  background-color: rgba(255,255,255,.35);
  box-shadow: inset 1px 1px 1px rgba(255,255,255,.5), 0px 10px 30px rgba(123,123,123,.08) !important;
  border-radius: 14px;
  .v-card__text {
    padding-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(255,255,255,0);
    // backdrop-filter: blur(10px);
    // box-shadow: 10px -10px 20px rgba(123,123,123,0.09);
  }


}
.theme--light.v-navigation-drawer {
  background-color: rgba(255,255,255,.5);
}
  .v-navigation-drawer__border {
    display:none;
  }

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 1px 1px 0 #e8eafc !important;
}
.v-application {
  .v-list-item--link:before {
    background-color: transparent !important;
  }
  .v-btn.theme--light, .v-list-item--active.v-list-item.v-list-item--link.theme--light {

    border-radius: 20px;
    background: rgb(255,255,255);
    background: linear-gradient(205deg, rgba(255,255,255,1) 0%, rgba(248,248,250,.9) 100%);
    box-shadow: inset 0px -1px 0px rgba(123,123,123,.14), 1px 1px 3px rgba(123,123,123,.24) !important;
  }
  .v-btn {
    text-transform: none;
  }
}


</style>
