<template lang="html">
  <v-navigation-drawer permanent absolute>
      <v-list
        class="pa-3"
        rounded
      >
        <v-list-item
          v-for="item in items"
          :to="item.path"
          :key="item.title"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import {
  mdiHome,
  mdiLock,
  mdiAccount
} from '@mdi/js'
export default {
  name: "NavLeft",
  data() {
    return {
      items: [
      {
        icon: mdiHome,
        title: 'Home',
        path: '/home'
      },
      {
        icon: mdiLock,
        title: 'Tokens',
        path: '/tokens'
      },
      {
        icon: mdiAccount,
        title: 'Profile',
        path: '/profile'
      }
    ]
    }
  },
  created() {

  }
}
</script>

<style lang="scss">
.theme--light.v-navigation-drawer {
  background-color: rgba(255,255,255,.2);
}
.v-navigation-drawer__content {


  .v-list-item__title {
      font-family: Helvetica Neue;
      font-size: 16px;
      letter-spacing: 1px;
  }
}
</style>
